import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import styled from 'styled-components';

// Styling For Navigation Bar

const Box = styled.div`
top: 0;
z-index: 100;
color: white;
position: sticky;
padding: 0px 20px;
letter-spacing: 3px;
font-family: 'Quicksand';  
background-color: #EEEEEE;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #232526, #414345);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #232526, #414345); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
`


// Navigation Bar

function Navi() {
    return (
        <Box>
            <Navbar expand="md" className="navbar-dark">
                <Navbar.Brand href="/">JOSE TYLER</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="./Projects">Projects</Nav.Link>
                        <Nav.Link href="./Resume">Resume</Nav.Link>                       
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Box>
    );
}

export default Navi;