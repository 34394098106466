import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
/* padding: 0px 10px; */
color: white;
background: #181818;  /* fallback for old browsers */
//background: -webkit-linear-gradient(to right, #232526, #414345);  /* Chrome 10-25, Safari 5.1-6 */
//background: linear-gradient(to right, #000000, #212121); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ 
font-family: 'Quicksand';
`
const Links = styled.a`
    color: white;

    :hover{
        color: #EC2E5F;
    }
`
const Stamp = styled.div`
color: #EC2E5F;
text-align: center;
padding-bottom: 1rem;
`

function Footer() {
    return (
        <Container>
            < footer class="page-footer font-small unique-color-dark">
                <div style={{ backgroundColor: "#EC2E5F" }}>
                    <div class="container">
                        <div class="row py-4 d-flex align-items-center">

                            <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                                <h6 class="mb-0" style={{ color: "black" }}>Connect with me on social media!</h6>
                            </div>

                            <div class="col-md-6 col-lg-7 text-center text-md-right">
                                <a href="https://www.github.com/" target="blank" class="fb-ic">
                                    <i class="fab fa-facebook white-text mr-4" style={{ color: "white" }}> </i>
                                </a>
                                <a href="https://www.instagram.com/" target="blank" class="ins-ic">
                                    <i class="fab fa-instagram white-text mr-4" style={{ color: "white" }}> </i>
                                </a>
                                <a href="https://www.github.com/" target="blank" class="gh-ic">
                                    <i class="fab fa-github white-text " style={{ color: "white" }}> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container text-center text-md-left mt-5">
                    <div class="row mt-3">

                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 class="text-uppercase font-weight-bold">Thank You</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>Thank you for viewing my portfolio! Feel free to contact me with any questions you may have. </p>
                        </div>


                        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase font-weight-bold">Where I Studied</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>
                                <Links href="https://perscholas.org/" target="blank_" >Perscholas</Links>
                            </p>
                            <p>
                                <Links href="https://generalassemb.ly/" target="blank_">General Assembly</Links>
                            </p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 class="text-uppercase font-weight-bold">Contact Info</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>
                                <i class="fas fa-phone mr-3"></i>678-615-1482</p>
                            <p>
                                <i class="fas fa-envelope mr-2"></i>JoseTyler@icloud.com</p>
                        </div>
                    </div>
                </div>
                <Stamp
                >Designed by Jose Tyler
                </Stamp>
            </footer >
        </Container>
    );
}

export default Footer;