import React from 'react';
import styled from 'styled-components';
import { Button, Card, CardDeck, Carousel } from "react-bootstrap";


// Styling For Home Page

const Container = styled.div`
overflow-x: hidden;
background: #83a4d4;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #232526, #414345);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #232526, #414345); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`
const Head1 = styled.h1`
color: #EC2E5F;
text-align: center;
padding-top: 40px;
padding-bottom: 20px;
font-family: Pacifico, cursive;
font-size: 50px;
text-shadow: 2px 2px #000000;
@media (max-width: 767px) {
    padding: 20px 15px;
    font-size: 45px;
  }
`
const Head2 = styled.h1`
color: #878787;
text-align: center;
padding-top: 40px;
padding-bottom: 20px;
font-family: Pacifico, cursive;
font-size: 50px;
text-shadow: 2px 2px #000000;
`
const Img = styled.img`
padding: 40px 0px;
width: 50%;
display: flex;
margin: auto;
border-radius: 50%;
@media (max-width: 767px) {
    width: 70%;
    
  }
`
const Img2 = styled.img`
padding: 20px 0px 80px 0px;
width: 80%;
display: flex;
margin: auto;
@media (max-width: 767px) {
    padding: 20px 0px 40px 0px;
    width: 92%;
  }
`
const Carouselo = styled.div`
padding: 40px 40px;
width: 100%;
height: auto;
display: flex;
margin: 0 auto;
@media (max-width: 767px) {
    padding: 20px 17px;
  }
`
const P1 = styled.p`
text-align: center;
color: white;
margin: 0 50px;
padding-top: 10px;
font-family: Abel;
@media (max-width: 767px) {
    margin: 0 20px;
  }
`
const P2 = styled.p`
text-align: center;
color: white;
margin: 0 50px;
padding-top: 10px;
font-family: Abel;
@media (max-width: 767px) {
    margin: 0 20px;
  }
`
const P3 = styled.p`
text-align: center;
color: white;
padding: 20px 70px 0px 70px;
font-family: Abel;
@media (max-width: 767px) {
    padding: 0 20px;
  }
`
const Deck = styled.div`
padding: 60px 40px;
@media (max-width: 767px) {
    padding: 30px 20px;
  }
`

// Home Page

function Home() {
    return (

        <Container>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">

                    <Head1>React Developer</Head1>
                    <P1>Welcome to my portfolio! My name is Jose Tyler & I am a React Developer with a passion for design. My coding studies include a Software Engineering Immersive course at General Assembly & a Front-End Development course at Perscholas.
                </P1>
                    <Img src="images/jose.png" alt="" class="img-fluid mb-4" />

                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">

                    <Head2>Hobbies</Head2>
                    <P2>I enjoy Photography and Graphic Design in my spare time. I have always had high interest in designing. My choice of camera is a Sony A7R3 & I like using the Adobe Creative Suite for edits & designing. Take a look at some of my work below. </P2>
                    <Carouselo>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/Graphic2.png"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/Photo1.png"
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/Graphic1.png"
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/Photo2.png"
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Carouselo>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <Head2>Projects</Head2>
                    <P3>Here are a few projects I have created for showcasing purposes during my time at General Assembly & Perscholas. Technologies used will include React, Javascript, CSS, HTML, Node.js, Ajax, Github, Bootstrap, & SASS to name a few. Check out my projects and let me know what you think? </P3>
                    <Deck>
                        <CardDeck className="text-center text-light">
                            <Card style={{ backgroundColor: "#1F1F1F" }} >
                                <Card.Img variant="top" src="images/Hang.png" />
                                <Card.Body>
                                    <Card.Title>"HANG IT UP"</Card.Title>
                                    <Card.Text>
                                        An application that allows users to play a Hang Man game with a scary twist based off the horror movie "IT"! Technologies used include HTML, CSS, & Javascript.
      </Card.Text>
                                    <Button variant="primary" href="https://hangitup.netlify.app/">Enter Website</Button>
                                </Card.Body>
                                <Card.Footer>
                                    <a href="https://github.com/JoseTyler/Hang-It-Up" target="blank_"><small className="text-muted">View Source Code</small></a>
                                </Card.Footer>
                            </Card>
                            <Card style={{ backgroundColor: "#1F1F1F" }} >
                                <Card.Img variant="top" src="images/Diego.png" />
                                <Card.Body>
                                    <Card.Title className="text-center">"DJ RIDERDIE"</Card.Title>
                                    <Card.Text>
                                        An application that allows fans to view a DJ's bio, listen to their music, & scheduling. Techonologies used include React, HTML, CSS, & Javascript.
                                    </Card.Text>
                                    <Button variant="primary" href="">Enter Website</Button>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">View Source Code</small>
                                </Card.Footer>
                            </Card>
                            <Card style={{ backgroundColor: "#1F1F1F" }} >
                                <Card.Img variant="top" src="images/FortLyfe.png" />
                                <Card.Body>
                                    <Card.Title className="text-center">"FORTLYFE"</Card.Title>
                                    <Card.Text>
                                        An application that allows FortNite players to view "Free Agent" players looking for a squad to join! Technologies used include React, HTML, CSS, & Javascript.
      </Card.Text>
                                    <Button variant="primary" href="https://fathomless-spire-62774.herokuapp.com/">Enter Website</Button>
                                </Card.Body>
                                <Card.Footer>
                                    <a href="https://github.com/JoseTyler/FortLyfe" target="blank_"><small className="text-muted">View Source Code</small></a>
                                </Card.Footer>
                            </Card>
                        </CardDeck>
                    </Deck>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <Head1>Design Resume</Head1>
                    <Img2 src="images/Resume.png" alt="" class="img-fluid mb-4" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <Head1>General Resume</Head1>
                    <Img2 src="images/GeneralResume.png" alt="" class="img-fluid mb-4" />
                </div>
            </div>
        </Container>
    );

}


export default Home;