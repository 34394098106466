import React from 'react';
import styled from 'styled-components';

// Styling For Hobbies Page

const Container = styled.div`
background: #83a4d4;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #232526, #414345);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #232526, #414345); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const Head1 = styled.h1`
color: #EC2E5F;
text-shadow: 2px 2px #000000;
text-align: center;
padding-top: 70px;
font-family: Pacifico;
font-size: 50px;
`

const Img = styled.img`
padding: 40px;
width: 100%;
display: flex;
margin: 0 auto;
`

// Resume Page

function Resume() {
    return (
        <Container>
            <div class="row">
                <div class="col-md-12 col-lg-6">

                    <Head1>Design Resume</Head1>

                    <Img src="images/Resume.png" alt="" class="img-fluid mb-4" />

                </div>
                <div class="col-md-12 col-lg-6">

                    <Head1>General Resume</Head1>
                    <Img src="images/GeneralResume.png" alt="" class="img-fluid mb-4" />

                </div>
            </div>
        </Container>

    );
}

export default Resume;