import React from 'react';
import styled from 'styled-components';
import { Button, Card, CardDeck} from "react-bootstrap";

// Styling For Hobbies Page

const Container = styled.div`

background: #83a4d4;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #232526, #414345);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #232526, #414345); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const Head1 = styled.h1`
color: #EC2E5F;
text-shadow: 2px 2px #000000;
text-align: center;
padding-top: 70px;
font-family: Pacifico, cursive;
font-size: 50px;
`
const Deck = styled.div`
padding: 60px 40px;
`
const P1 = styled.p`
text-align: center;
color: white;
margin: 0 150px;
padding-top: 20px;
font-family: Abel;
`

// Hobbies Page

function Projects() {
    return (
        <Container>
            <Head1> My Projects</Head1>
            <P1>Here are a few projects I have created for showcasing purposes during my time at General Assembly & Perscholas. Technologies used will include React, Javascript, CSS, HTML, Node.js, Ajax, Github, Bootstrap, & SASS to name a few. Check out my projects and let me know what you think? </P1>
            <Deck>
                <CardDeck className="text-center text-light">
                    <Card style={{ backgroundColor: "#1F1F1F" }} >
                        <Card.Img variant="top" src="images/Hang.png" />
                        <Card.Body>
                            <Card.Title>"HANG IT UP"</Card.Title>
                            <Card.Text>
                                An application that allows users to play a Hang Man game with a scary twist based off the horror movie "IT"! Technologies used include HTML, CSS, & Javascript.
      </Card.Text>
                            <Button variant="primary" href="https://hangitup.netlify.app/">Enter Website</Button>
                        </Card.Body>
                        <Card.Footer>
                            <a href="https://github.com/JoseTyler/Hang-It-Up" target="blank_"><small className="text-muted">View Source Code</small></a>
                        </Card.Footer>
                    </Card>
                    <Card style={{ backgroundColor: "#1F1F1F" }} >
                        <Card.Img variant="top" src="images/Diego.png" />
                        <Card.Body>
                            <Card.Title className="text-center">"DJ RIDERDIE"</Card.Title>
                            <Card.Text>
                                An application that allows fans to view a DJ's bio, listen to their music, & scheduling. Techonologies used include React, HTML, CSS, & Javascript.
                                    </Card.Text>
                            <Button variant="primary" href="">Enter Website</Button>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">View Source Code</small>
                        </Card.Footer>
                    </Card>
                    <Card style={{ backgroundColor: "#1F1F1F" }} >
                        <Card.Img variant="top" src="images/FortLyfe.png" />
                        <Card.Body>
                            <Card.Title className="text-center">"FORTLYFE"</Card.Title>
                            <Card.Text>
                                An application that allows FortNite players to view "Free Agent" players looking for a squad to join! Technologies used include React, HTML, CSS, & Javascript.
      </Card.Text>
                            <Button variant="primary" href="https://fathomless-spire-62774.herokuapp.com/">Enter Website</Button>
                        </Card.Body>
                        <Card.Footer>
                            <a href="https://github.com/JoseTyler/FortLyfe" target="blank_"><small className="text-muted">View Source Code</small></a>
                        </Card.Footer>
                    </Card>
                </CardDeck>
            </Deck>
            
            </Container>
        

    );
}

export default Projects;